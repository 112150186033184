import React, { FC, useEffect, useState } from "react"
import Cookies from "js-cookie"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import classNames from "classnames"
import { env } from "@/config"
import { generateFormQueryParams } from "@/services/links"
import { useGoogleMaps } from "@/app/hooks/use-google-maps"
import { EMAIL_STORAGE } from "@/constants/storage"
import { catBreeds, dogBreeds } from "@/constants/pet-breed/form-breeds"
import {
  getSizeBasedOnWeight,
  transformShipmentBody,
  transformShipmentBodyForStorage,
} from "@/util/validation"
import ShipmentService from "@/services/shipment"
import { PlaceInputPlaceholder } from "../location-placeholder"
import { PlaceInputAutocomplete } from "../location"
import type { GoogleAddressData } from "../location"
import { Email } from "../email"
import Autocomplete from "./autocomplete"
import CustomSelect from "./custom-select"

const addressSchema = z.object({
  formatted_address: z.string(),
  city: z.string(),
  state_code: z.string(),
  lat: z.number(),
  lng: z.number(),
  zip: z.string().nullable(),
})

const transportationSchema = z.object({
  pickup: addressSchema,
  delivery: addressSchema,
  email: z.string().email("Valid email is required"),
  name: z.string().min(1, "Name is required"),
  breed: z.string().min(1, "Breed is required"),
  size: z.string().min(1, "size is required"),
  count: z.number().min(1, "At least one is required"),
  pickupDate: z.string().min(1, "Pickup date is required"),
  isOlderThan8Weeks: z.literal(true, {
    errorMap: () => ({ message: "Animal must be older than 8 weeks" }),
  }),
})
const oldCustomerSchema = z.object({
  pickup: addressSchema,
  delivery: addressSchema,
  name: z.string().min(1, "Name is required"),
  breed: z.string().min(1, "Breed is required"),
  size: z.string().min(1, "size is required"),
  count: z.number().min(1, "At least one is required"),
  pickupDate: z.string().min(1, "Pickup date is required"),
  isOlderThan8Weeks: z.literal(true, {
    errorMap: () => ({ message: "Animal must be older than 8 weeks" }),
  }),
})
export type Props = {
  buttonLink?: string
  buttonLabel?: string
  animalType?: string
}

export const PremiumHeroListingForm: FC<Props> = (props) => {
  const { buttonLabel, buttonLink, animalType } = props
  const APP_HOST = env("APP_HOST")
  const TOKEN = env("TOKEN")
  const token = Cookies.get(TOKEN as string)
  const LINK = buttonLink ?? "/shipments/new"
  const [focusedField, setFocusedField] = useState<
    "pickup" | "delivery" | null
  >(null)
  const schema = token ? oldCustomerSchema : transportationSchema
  const {
    handleSubmit,
    control,
    setFocus,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<{
    pickup: null | GoogleAddressData
    delivery: null | GoogleAddressData
    email: string
    name: string
    breed: string
    size: string
    count: number
    pickupDate: string
    isOlderThan8Weeks: boolean
  }>({
    defaultValues: {
      pickup: null,
      delivery: null,
      email: "",
      name: "",
      breed: "",
      size: "",
      count: 1,
      pickupDate: "",
      isOlderThan8Weeks: true,
    },
    resolver: zodResolver(schema),
  })

  const { mapsLoaded, loadMaps, google } = useGoogleMaps()

  useEffect(() => {
    if (mapsLoaded && focusedField != null) {
      setFocus(focusedField)
    }
  }, [mapsLoaded, focusedField, setFocus])

  const onSubmit = handleSubmit(async (data) => {
    if (env("FF_LISTING_FORM_ENABLED") === "0") {
      let queryParams: Record<string, string | number> = {}
      if (data.pickup) {
        queryParams = {
          ...queryParams,
          pickupFA: data.pickup.formatted_address,
          pickupSC: data.pickup.state_code,
          pickupCt: data.pickup.city,
          pickupLat: data.pickup.lat,
          pickupLng: data.pickup.lng,
          pickupZip: data.pickup.zip ?? "",
        }
      }

      if (data.delivery) {
        queryParams = {
          ...queryParams,
          deliveryFA: data.delivery.formatted_address,
          deliverySC: data.delivery.state_code,
          deliveryCt: data.delivery.city,
          deliveryLat: data.delivery.lat,
          deliveryLng: data.delivery.lng,
          deliveryZip: data.delivery.zip ?? "",
        }
      }

      queryParams = {
        ...queryParams,
        email: data.email,
        ...(animalType === "dog"
          ? { dogName: data.name, dogBreed: data.breed }
          : {
              name: data.name,
              breed: data.breed,
            }),

        count: data.count,
        pickupDate: data.pickupDate,
        platform: "lp",
      }

      if (data.email) {
        sessionStorage.setItem(EMAIL_STORAGE, data.email)
      }

      const formQueryParams = generateFormQueryParams({ query: queryParams })

      window.location.assign(`${APP_HOST}${LINK}${formQueryParams}`)
    } else {
      try {
        let values = {}
        const weight = dogBreeds.find((item) => item.name === data.breed)
          ?.weight
        values = {
          email: data.email,
          ...(animalType === "dog"
            ? { dogName: data.name, dogBreed: data.breed }
            : {
                name: data.name,
                breed: data.breed,
              }),

          count: data.count,
          delivery: { address: data.delivery },
          pickup: { date: data.pickupDate, address: data.pickup },
          platform: "lp",
          size: data.size.toLowerCase(),
          weight,
        }

        if (data.email) {
          sessionStorage.setItem(EMAIL_STORAGE, data.email)
        }

        const shipment = await transformShipmentBody(values, animalType, google)

        const cookie = await transformShipmentBodyForStorage(
          values,
          animalType,
          google,
        )
        Cookies.set("create-shipment-form", JSON.stringify(cookie), {
          expires: 1,
          Domain: ".citizenshipper.com",
          secure: true,
          sameSite: "Lax",
        })

        if (token) {
          const response = await ShipmentService.createShipment(shipment, token)
          if (response) {
            window.location.assign(`${APP_HOST}/shipments/`)
          }
        } else {
          const response = await ShipmentService.validateShipment(shipment)
          if (response) {
            window.location.assign(`${APP_HOST}/shipments/new/account`)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  })

  const updateCount = (type: "increment" | "decrement") => {
    const currentValue = getValues("count")
    const newValue = Math.max(1, currentValue + (type === "increment" ? 1 : -1))
    setValue("count", newValue)
  }

  return (
    <form onSubmit={onSubmit} className="space-y-5">
      <div className="md:grid grid-cols-5 gap-6 md:gap-5">
        {/* Name */}
        <div className="col-span-1">
          <label className="flex uppercase text-gray16 text-base">
            {animalType}'s Name<span className="text-gray16">*</span>
          </label>
          <div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="w-full p-4 mt-4 focus:outline-none text-sm bg-gray50 h-[56] border-b  text-neutral-600 border-black"
                  data-testid="pet-name"
                />
              )}
            />
            {errors.name && (
              <span className="text-red-500 text-sm">
                {errors.name.message}
              </span>
            )}
          </div>
        </div>
        {/* Breed */}
        <div className="col-span-1 mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            Breed<span className="text-gray16">*</span>
          </label>
          <div>
            <Controller
              name="breed"
              control={control}
              render={({ field }) => {
                const breeds =
                  animalType === "dog"
                    ? dogBreeds.map((item) => item.name)
                    : catBreeds

                const handleBreedChange = (selectedBreed: string) => {
                  field.onChange(selectedBreed)

                  if (animalType === "dog") {
                    const selectedBreedData = dogBreeds.find(
                      (item) => item.name === selectedBreed,
                    )
                    const assumedWeight = selectedBreedData?.weight
                    const newSize = getSizeBasedOnWeight(assumedWeight)
                    setValue("size", newSize || "small", {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                }

                return (
                  <Autocomplete
                    options={breeds}
                    value={field.value}
                    onChange={handleBreedChange}
                    placeholder="Select Breed"
                    testId="pet-breed"
                    name="pet-breed"
                  />
                )
              }}
            />
            {errors.breed && (
              <span className="text-red-500 text-sm">
                {errors.breed.message}
              </span>
            )}
          </div>
        </div>
        {/*  Size */}
        <div className="col-span-1 mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            {animalType} Size<span className="text-gray16">*</span>
          </label>
          <Controller
            name="size"
            control={control}
            render={({ field }) => (
              <CustomSelect
                options={["X-small", "Small", "Medium", "Large", "X-large"]}
                value={field.value || ""}
                onChange={field.onChange}
                placeholder="Select Size"
                testId="pet-size"
              />
            )}
          />
          {errors.size && (
            <span className="text-red-500 text-sm">{errors.size.message}</span>
          )}
        </div>
        {/* Number of Dogs */}
        <div className="col-span-1  mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            <span className="hidden md:block lg:hidden">
              No. of {animalType}s
            </span>
            <span className="hidden lg:block">Number of {animalType}s</span>
            <span className="block md:hidden">
              How many {animalType}s are you transporting
            </span>
          </label>
          <div className="flex items-center p-0 w-full relative text-base bg-gray50 h-[57px] focus:outline-none text-neutral-600 border-black border-b mt-4">
            <Controller
              name="count"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  readOnly
                  className="w-full h-full text-center bg-transparent focus:outline-none"
                  data-testid="pet-count"
                />
              )}
            />
            <button
              type="button"
              onClick={() => updateCount("decrement")}
              className="px-3 py-2 bg-gray-100 hover:bg-gray-200"
              data-testid="decrement-count"
            >
              -
            </button>
            <button
              type="button"
              onClick={() => updateCount("increment")}
              className="px-3 py-2 bg-gray-100 hover:bg-gray-200"
              data-testid="increment-count"
            >
              +
            </button>
          </div>
        </div>
        {/* Ideal Pickup Date */}
        <div className="col-span-1 mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            <span className="hidden md:block lg:hidden">Pickup Date</span>
            <span className="hidden xl:block"> Ideal Pickup Date</span>
            <span className="block md:hidden">Ideal Pickup Date</span>
            <span className="text-gray16">*</span>
          </label>
          <div>
            <Controller
              name="pickupDate"
              control={control}
              render={({ field }) => {
                const minDate = new Date().toISOString().split("T")[0]
                return (
                  <input
                    {...field}
                    min={minDate}
                    type="date"
                    className="p-4 w-full min-w-[180px] sm:min-w-full relative text-sm bg-gray50 h-[57px] focus:outline-none text-neutral-600 border-black border-b mt-4"
                  />
                )
              }}
            />
            {errors.pickupDate && (
              <span className="text-red-500 text-sm">
                {errors.pickupDate.message}
              </span>
            )}
          </div>
        </div>
      </div>

      <div
        className={classNames(
          "grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 md:gap-x-6 lg:gap-x-8",
          token ? "md:grid-cols-3" : "md:grid-cols-4",
        )}
      >
        <div>
          <Controller
            name="pickup"
            control={control}
            render={({ field, fieldState }) => {
              const error = fieldState.error
                ? "Select Pickup Location"
                : undefined

              return mapsLoaded ? (
                <PlaceInputAutocomplete
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Pickup location"
                  type="pickup"
                  error={error}
                  id="pickup-location"
                  ref={field.ref}
                  required
                />
              ) : (
                <PlaceInputPlaceholder
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Pickup location"
                  type="pickup"
                  id="pickup-location"
                  required
                  onFocus={() => {
                    loadMaps()
                    setFocusedField("pickup")
                  }}
                />
              )
            }}
          />
        </div>
        <div>
          <Controller
            name="delivery"
            control={control}
            render={({ field, fieldState }) => {
              const error = fieldState.error
                ? "Select Delivery Location"
                : undefined

              return mapsLoaded ? (
                <PlaceInputAutocomplete
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Delivery location"
                  type="dropoff"
                  error={error}
                  id="dropoff-location"
                  ref={field.ref}
                  required
                />
              ) : (
                <PlaceInputPlaceholder
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Delivery location"
                  type="dropoff"
                  required
                  id="dropoff-location"
                  onFocus={() => {
                    loadMaps()
                    setFocusedField("delivery")
                  }}
                />
              )
            }}
          />
        </div>
        {!token && (
          <div>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => {
                const error = fieldState.error
                  ? fieldState.error.message
                  : undefined

                return (
                  <div>
                    <Email
                      value={field.value}
                      ref={field.ref}
                      onChange={field.onChange}
                      label={"Email"}
                      error={error}
                      id="email"
                      required
                    />
                  </div>
                )
              }}
            />
          </div>
        )}

        <div>
          <button
            className="w-full py-5 uppercase text-center bg-blue7 hover:bg-blue-700 mt-10 text-white text-md"
            onClick={onSubmit}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
      <div>
        <div className="flex items-start">
          <Controller
            name="isOlderThan8Weeks"
            control={control}
            render={({ field, fieldState }) => {
              const error = fieldState.error
                ? "Animal must be older than 8 weeks"
                : null

              return (
                <label className="flex items-center space-x-4 md:-mt-2 text-gray16">
                  <input
                    type="checkbox"
                    checked={field.value}
                    onChange={field.onChange}
                    className="rounded text-gold"
                  />
                  <span className="text-sm">
                    Confirm pets are older than 8 weeks at the time of transport
                    (USDA requirement)*
                  </span>
                </label>
              )
            }}
          />
          {errors.isOlderThan8Weeks && (
            <span className="text-red-500 text-[14px]">
              {errors.isOlderThan8Weeks.message}
            </span>
          )}
        </div>
      </div>
    </form>
  )
}
